
::placeholder {
    color: #bbb !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #bbb !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
   color: #bbb !important;
}


.multistep-form input[type="text"],
.multistep-form input[type="email"],
.multistep-form input[type="password"],
.multistep-form select.form-control,
.multistep-form .select2-selection,
.multistep-form textarea,
.multistep-form textarea.form-control {
	/*height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    background: #f8f8f8;
    border: 1px solid #ddd;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #888;*/
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

.multistep-form textarea,
.multistep-form textarea.form-control {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 30px;
}

.multistep-form input[type="text"]:focus,
.multistep-form input[type="email"]:focus,
.multistep-form input[type="password"]:focus,
.multistep-form textarea:focus,
.multistep-form textarea.form-control:focus {
	outline: 0;
	background: #fff;
    border: 1px solid #ccc;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.multistep-form input[type="text"]:-moz-placeholder, .multistep-form input[type="email"]:-moz-placeholder, .multistep-form input[type="password"]:-moz-placeholder,
.multistep-form textarea:-moz-placeholder, .multistep-form textarea.form-control:-moz-placeholder { color: #888; }

.multistep-form input[type="text"]:-ms-input-placeholder, .multistep-form input[type="email"]:-ms-input-placeholder, .multistep-form input[type="password"]:-ms-input-placeholder,
.multistep-form textarea:-ms-input-placeholder, .multistep-form textarea.form-control:-ms-input-placeholder { color: #888; }

.multistep-form input[type="text"]::-webkit-input-placeholder, .multistep-form input[type="email"]::-webkit-input-placeholder, .multistep-form input[type="password"]::-webkit-input-placeholder,
.multistep-form textarea::-webkit-input-placeholder, .multistep-form textarea.form-control::-webkit-input-placeholder { color: #888; }

.multistep-form .multistep-button {
	height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    background: #362c33; /*#5abab3;*/
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

.multistep-form .multistep-button:hover { opacity: 0.6; color: #fff; }

.multistep-form .multistep-button:active { outline: 0; opacity: 0.6; color: #fff; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }

.multistep-form .multistep-button:focus { outline: 0; opacity: 0.6; background: #3c343a; /*#5abab3;*/ color: #fff; }

.multistep-form .multistep-button:active:focus, .multistep-form .multistep-button.active:focus { outline: 0; opacity: 0.6; background: #3c343a; /*#5abab3;*/ color: #fff; }

.multistep-form .multistep-submit {
    background: #09044a; /*#181116;*/ /*#326763;*/
}

.multistep-form .multistep-submit:hover,
.multistep-form .multistep-submit:active,
.multistep-form .multistep-submit:focus {
    background: #09044a; /*#2e212a;*/ /*#326763;*/
}

.multistep-form .select2-container {
    width: 100% !important;
}

.multistep-form .select2-choices {
  min-height: 50px !important;
  max-height: 50px !important;
  overflow-y: auto !important;
}

.multistep-form .select2-container .select2-selection--single {
    height: 50px !important;
    border: 1px solid #ddd;
    outline: none;
}

.multistep-form .select2-container .select2-selection__rendered {
    line-height: 50px !important;
    color: #888 !important;
}

.multistep-form .select2-container .select2-selection__arrow {
    height: 50px !important;
}

.multistep-form label {
    font-weight: normal;
}
