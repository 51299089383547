// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Icon css
@import '../fonts/icomoon/style.css';
@import '../fonts/flaticon/font/flaticon.css';

// Account css
@import '../css/account.css';