
@font-face {
    font-family: RobotoRegular;
    src: url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: RobotoLight;
    src: url('../../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: NunitoSans;
    src: url('../../fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: MerriweatherRegular;
    src: url('../../fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
    font-family: MerriweatherLight;
    src: url('../../fonts/Merriweather/Merriweather-Light.ttf') format('truetype');
}

html, body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    font-family: RobotoLight, sans-serif !important;
    font-size: 1.125rem !important;
    background: #fafafa;
    color: #252525 !important;
    overflow-x: hidden;
}

input, input:focus, input:active,
select, select:focus, select:active {
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
}

input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active,
select,
select:hover,
select:focus,
select:active
{
    outline:0px !important;
    box-shadow: none !important;
}

a {
    color: #252525 !important;
}

a:hover, a:focus {
    color: #ef3742 !important;
}

#menu {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
}

ul.navbar-nav > li a {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    color: #252525 !important;
    padding: 1.125rem 0.5rem !important;
    position: relative;
    display: block;
}

ul.navbar-nav > li.active > a::after {
    content: "";
    position: absolute;
    background-color: #ef3742;
    height: 2px;
    width: 100%;
    bottom: 10px;
    left: 0;
}

ul.navbar-nav > li a.btn-black {
    color: #fff !important;
    background-color: #252525 !important;
    border-color: #252525 !important;
    border-radius: 0px !important;
}

.promotion-bar {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    background: rgba(23,204,189,0.08);
    border-top: 1px solid rgba(23,204,189,0.21);
    border-bottom: 1px solid rgba(23,204,189,0.21);
    font-size: 12px;
    font-weight: 600;
}

.sectionH1 {
    font-family: MerriweatherRegular, sans-serif !important;
    font-size: 42px;
    font-weight: 700;
    color: #3c3c3c;
}

.sectionP {
    font-size: 25px;
    line-height: normal;
}

.round-btn-link {
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    box-sizing: border-box;
    border: 2px solid;
    height: 60px;
    margin-bottom: 16px;
    min-width: 285px;
}

.round-btn-link i {
    font-size: 28px;
    margin-right: 5px;
}

.btn-link-inventory {
    background-color: #5284ed;
    border-color: #5284ed;
    color: #fff !important;
    justify-content: center;
    padding: 0 30px;
    transition: background-color 0.25s ease;
}

.btn-link-inventory:hover,
.btn-link-inventory:focus,
.btn-link-inventory:active {
    text-decoration: none;
    color: #fff !important;
}

.btn-link-testament {
    border-color: #666;
    color: #242424 !important;
    justify-content: center;
    padding: 0 30px;
    transition: border-color 0.25s ease;
}

.btn-link-testament:hover,
.btn-link-testament:focus,
.btn-link-testament:active {
    text-decoration: none;
    color: #242424 !important;
}

.highlight-word {
    display: inline-block;
    position: relative;
    z-index: 0;
}

.highlight-word:before {
    content: '';
    background: url('/img/text-highlight.png') no-repeat center center;
    background-size: 100% 60px;
    width: 108%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    transform: translate(-3%, 0);
}

.footer-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

footer {
    font-size: 0.9rem !important
}

.sectionH2 {
    font-family: RobotoRegular, sans-serif !important;
    font-size: 24px !important;
    font-weight: 700;
    color: #3c3c3c;
}

.sectionH3 {
    margin-top: 36px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    color: #c5c5c5;
    text-transform: uppercase;
}

.sectionH5 {
    font-family: RobotoRegular, sans-serif !important;
    font-size: 18px !important;
    font-weight: 700;
    color: #3c3c3c;
}

.security-wrapper {
    background-color: #f5f3f3;
    padding: 20px 20px;
    box-sizing: border-box;
    border-radius: 10px;
}

#coming-soon {
    width: 100%;
    height: 700px;
}

input[type=radio]{
    transform:scale(2) !important;
    margin-right: 10px;
}

.hideBlock {
    display: none;
}

.customTextarea {
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-dark-red{
    cursor: pointer !important;
	color: #fff !important;
	background-color: #663c5b !important;
	border-color: #663c5b !important;
    /*font-weight: bold !important;*/
    letter-spacing: 2px;
    outline: none;
}

.btn-dark-red:hover{
    cursor: pointer !important;
	color: #fff !important;
	background-color: #7a4b6e !important;
	border-color: #7a4b6e !important;
    /*font-weight: bold !important;*/
    outline: none;
}

.btn-outline-dark-red {
    cursor: pointer !important;
	color: #663c5b !important;
	border-color: #663c5b !important;
    background-color: #fff !important;
    /*font-weight: bold !important;*/
    letter-spacing: 2px;
    outline: none;
}

.btn-outline-dark-red:hover {
    cursor: pointer !important;
	color: #fff !important;
	background-color: #7a4b6e !important;
	border-color: #7a4b6e !important;
    /*font-weight: bold !important;*/
    outline: none;
}

.jumbotron {
    background-color: #f7e6e9 !important;
}

#assetForm {
    min-height: 550px;
}

.card-header {
    background-color: #f7e6e9 !important;
}

.w-25 {
    width: 25% !important;
}

.h-100 {
    height: 100%;
}

.editSucceeded, .editFailed, .deleteSucceeded, .deleteFailed {
    display: none;
}

.modal .modal-750 {
    width: auto;
    max-width: 750px !important;
}

.inventory-title-icon,
.testament-title-icon {
    font-size: 35px;
    color: #ef3742;
}

.bg-asset {
    background-color: #ebeafb; /*#151043;*/
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.form-title-label {
    font-size: 1.2em;
    font-weight: bold !important;
    margin-top: 1rem;
    color: #f9d0d8;
}

.form-group-bg {
    background-color: #7a486d;
    padding: 10px;
    margin-bottom: 0px !important;
}

.col-left-border-right {
    border-right: 1px solid #7a486d;
    padding-bottom: 2rem;
}

.col-right-padding-top {
    padding-top: 0rem;
}

.video-js {
    width: 100%;
}