
/***** Top content *****/

.form-box {
	padding-top: 40px;
}

.form-top {
	overflow: hidden;
	padding: 0 25px 15px 25px;
	background: #fff;
	-moz-border-radius: 4px 4px 0 0; -webkit-border-radius: 4px 4px 0 0; border-radius: 4px 4px 0 0;
	text-align: left;
}

.form-top-left {
	float: left;
	width: 75%;
	padding-top: 25px;
}

.form-top-left h3 { margin-top: 0; }

.form-top-right {
	float: left;
	width: 25%;
	padding-top: 5px;
	font-size: 66px;
	color: #ddd;
	line-height: 100px;
	text-align: right;
}

.form-bottom {
	padding: 25px 25px 30px 25px;
	background: #eee;
	-moz-border-radius: 0 0 4px 4px; -webkit-border-radius: 0 0 4px 4px; border-radius: 0 0 4px 4px;
	text-align: left;
}

form .form-bottom textarea {
	height: 100px;
}

form .form-bottom button.btn {
	min-width: 105px;
}

form .form-bottom .input-error {
	border-color: #e49593 !important;
}

form.multistep-form fieldset {
	display: none;
}


/***** Media queries *****/

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
	.form-bottom { padding-bottom: 25px; }
	form .form-bottom button.btn { margin-bottom: 5px; }
}


/***** Custom Change *****/
.form-box {
	padding-top: 0px;
}

.form-top {
	background-color: #f7e6e9; /*#ceedeb;*/
	color: #663c5b;
}

.form-bottom {
	background-color: #663c5b; /*#b1e6e2;*/
	color: #fff;
}

.form-top-left {
	color: #663c5b; /*#00796e;*/
}

.form-top-right {
	color: #d7c4c7; /*#b1e6e2;*/ /*#9ae5e0;*/
}

.form-top-left h3 {
	font-size: 1.5em;
}

.confirm-txt {
	margin-bottom: 30px;
}

.payment-form-group .select2-container {
	margin-bottom: 15px;
}

.p-method, .to-p-method  {
	display: none;
}

#business-empty{
	display: none;
	margin-top: 15px;
	text-align: center;
}

#div-alert-info {
	margin-top: 15px;
	text-align: center;
	display: none;
}

th[scope="row"] {
	width: 150px;
}

.panel-default>.panel-heading {
	color: #666;
	font-size: 1.1em;
	font-weight: bold;
}

.sender-p-method, .sender-r-method, .receiver-p-method {
	display: none;
}

.deal-exchange-rate {
	display: none;
}

.glyphicon-margin {
	margin-right: 10px;
}

.invalid-feedback {
	color: #ff859b !important;
}